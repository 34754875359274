import supportedBrowsers from "../broswerSupport/supportedBrowsers";
import { images } from "@constants/assets";

const isBroswerSupported = supportedBrowsers.test(navigator.userAgent);

if (!isBroswerSupported) {
  const errorDiv = document.createElement("div");
  errorDiv.setAttribute("id", "not-supported-browser-container");

  const errorImage = document.createElement("img");
  errorImage.src = images.errors.fetchData;
  errorImage.alt = "Error image";
  errorDiv.appendChild(errorImage);

  const errorText = document.createElement("h3");
  errorText.textContent =
    "We generally only support the most recent versions of major browsers. Upgrade your current browser version or switch to a different browser for a full experience.";
  errorDiv.appendChild(errorText);

  const appDiv = document.getElementById("app");
  appDiv.style = "display: none";
  document.body.insertBefore(errorDiv, appDiv);
}
